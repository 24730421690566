<template>
  <v-container fluid class="down-top-padding">
    <div class="page-breadcrumb">
      <v-card class="py-5 px-5">
        <h2 class="mb-1 text-center">{{ emp }} [ {{ descmesa }} ]</h2>
        <h2 class="text-center">{{ title }}</h2>
        <h4 class="text-center">{{ periodo }}</h4>
      </v-card>
    </div>
    <div v-if="estadoTipoOtros && isVotacion">
      <v-container fluid class="down-top-padding">
        <v-row justify="center">
          <v-col cols="12" sm="6" lg="6">
            <v-card class="mb-7 ">
              <v-card-text class="pa-5 text-center">
                <img :src="resVotacion.path" alt="user" class="rounded-circle" width="120" height="120" />
                <h2 class="mt-3 mb-0">USTED YA EMITIÓ SU VOTO:</h2>
                <h3 class="mt-3 mb-0">{{ resVotacion.nombres }}</h3>
                <small class="subtitle-2 font-weight-regular grey--text text--darken-3">
                  ({{ resVotacion.descripcion }})
                </small>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div v-else>
      <VotacionAdmin v-if="estadoTipoAdmin"></VotacionAdmin>
      <VotacionFormulario
        v-if="estadoTipoOtros"
        :iduser="iduser"
        :idtrab="idtrab"
        @emitvotacion="reload"
      ></VotacionFormulario>
    </div>
    <div v-if="showLoading" class="progress-background">
      <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
    </div>
  </v-container>
</template>

<script>
import { decryptData, pathImage } from '@/components4x/utils/utils4x';
import VotacionAdmin from '@/gmo_votacion/votar/votar/components/VotacionAdmin';
import VotarService from '@/gmo_votacion/votar/votar/services/VotarService';
export default {
  name: 'Votar',
  components: {
    VotacionAdmin,
    VotacionFormulario: () => import('../components/VotacionFormulario')
  },
  data: () => ({
    emp: '',
    title: '',
    periodo: '',
    descmesa: '',
    tipo: '',
    iduser: '',
    idtrab: '',
    showLoading: false,
    isVotacion: false,
    resVotacion: {
      nombres: '',
      descripcion: '',
      path: ''
    }
  }),
  methods: {
    async getVotacion() {
      this.showLoading = true;
      const res = await this.voteService.get().execResource('votacion', {
        ideleccion: localStorage.getItem('oko3ldkd543'),
        ideleccionusuario: this.iduser
      });

      if (res.data.length > 0) {
        this.resVotacion.nombres = decryptData(res.data[0].nombres);
        this.resVotacion.descripcion = decryptData(res.data[0].descripcion);
        this.resVotacion.path = pathImage(res.data[0].path);
        this.showLoading = false;
        return true;
      }
      this.resVotacion = { nombres: '', descripcion: '', path: '' };
      this.showLoading = false;
      return false;
    },
    async reload() {
      if (this.estadoTipoOtros) {
        this.isVotacion = await this.getVotacion();
      }
    },
    open() {},
    close() {}
  },
  computed: {
    estadoTipoAdmin() {
      return this.tipo === 'P';
    },
    estadoTipoOtros() {
      return this.tipo === 'S' || this.tipo === 'V' || this.tipo === 'U';
    }
  },
  async created() {
    this.voteService = this.$httpService(new VotarService(), this);
    this.emp = decryptData(localStorage.getItem('nc4rhtcc976'));
    this.descmesa = decryptData(localStorage.getItem('iow5csjr398'));
    this.title = decryptData(localStorage.getItem('k3rtxqdp234'));
    this.periodo = decryptData(localStorage.getItem('oq1rcizm635'));
    this.tipo = decryptData(localStorage.getItem('ppw3kkll432'));
    this.iduser = localStorage.getItem('ust0tare321');
    this.idtrab = localStorage.getItem('myt2jdju876');

    if (this.estadoTipoOtros) {
      this.isVotacion = await this.getVotacion();
    }
  }
};
</script>
