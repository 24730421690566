<template>
  <div>
    <div v-if="user.estado" class="d-flex justify-space-between mx-4">
      <h2 class="mb-1 text-center">{{ user.nombres }}</h2>
      <v-btn color="primary" fab dark small class="mb-2" @click="logout">
        <v-icon dark>mdi-login-variant</v-icon>
      </v-btn>
    </div>
    <VotacionAdminLogin v-if="!user.estado" @loadUser="loadUser"></VotacionAdminLogin>
    <VotacionFormulario
      v-else
      :iduser="user.ideleccionusuario"
      :idtrab="user.idtrabajador"
      @emitvotacion="logout"
    ></VotacionFormulario>
  </div>
</template>

<script>
import VotacionAdminLogin from '@/gmo_votacion/votar/votar/components/VotacionAdminLogin';
import VotacionFormulario from '@/gmo_votacion/votar/votar/components/VotacionFormulario';
import { decryptData } from '@/components4x/utils/utils4x';
export default {
  name: 'VotacionAdmin',
  components: { VotacionFormulario, VotacionAdminLogin },
  data: () => ({
    user: { estado: false, ideleccionusuario: '', idtrabajador: '', nombres: '' }
  }),
  methods: {
    loadUser({ estado, ideleccionusuario, idtrabajador, nombres }) {
      this.user.estado = estado;
      this.user.ideleccionusuario = ideleccionusuario;
      this.user.idtrabajador = idtrabajador;
      this.user.nombres = decryptData(nombres);
    },
    logout() {
      this.user = { estado: false, ideleccionusuario: '', idtrabajador: '', nombres: '' };
    }
  }
};
</script>

<style scoped></style>
