<template>
  <v-card class="elevation-4">
    <v-row class="d-flex justify-center">
      <v-col cols="12" sm="6" md="6" lg="6">
        <div class="pa-7 pa-sm-12">
          <div class="d-flex justify-center">
            <img src="@/assets/images/logo-agrovision.svg" alt="alt" width="80" height="80" />
          </div>
          <h2 class="font-weight-bold blue-grey--text text--darken-2 text-center">INGRESAR A CEDULA ELECTORAL</h2>
          <v-text-field
            v-model="dni"
            :rules="dniRules"
            label="DNI"
            class="mt-4"
            required
            outlined
            @keyup.enter="ingresar"
          ></v-text-field>
          <v-btn :disabled="loading" :loading="loading" color="primary" block class="mr-4" @click="ingresar">
            Ingresar
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import VotarService from '@/gmo_votacion/votar/votar/services/VotarService';
import Swal from 'sweetalert2';
import { decryptData, pathImage } from '@/components4x/utils/utils4x';

export default {
  name: 'VotacionAdminLogin',
  data: () => ({
    voteService: null,
    loading: false,
    dni: '',
    dniRules: [
      (v) => !!v || 'Dni es requerido',
      (v) => (v && v.length >= 8 && v.length <= 10) || 'El documento debe tener de 8 a 10 caracteres'
    ]
  }),
  methods: {
    async ingresar() {
      if (!(this.dni.length >= 8 && this.dni.length <= 10)) return;
      this.loading = true;

      this.candidates = [];
      let obj = { estado: false, ideleccionusuario: '', idtrabajador: '', nombres: '' };
      const res = await this.voteService.get().execResource('usuario', {
        idtrabajador: this.dni
      });

      if (res.data.length > 0) {
        obj = res.data[0];
        const resvot = await this.getVotacion(obj);
        if (resvot) {
          this.loading = false;
          this.dni = '';
          return;
        }
      }
      this.loading = false;
      this.$emit('loadUser', obj);
    },
    async getVotacion({ ideleccionusuario }) {
      const res = await this.voteService.get().execResource('votacion', {
        ideleccion: localStorage.getItem('oko3ldkd543'),
        ideleccionusuario: ideleccionusuario
      });

      if (res.data.length > 0) {
        await Swal.fire({
          title: 'USTED YA EMITIÓ SU VOTO:',
          html: `${decryptData(res.data[0].nombres)}<br>(${decryptData(res.data[0].descripcion)})`,
          imageUrl: pathImage(res.data[0].path),
          imageWidth: 150,
          imageHeight: 150,
          imageAlt: 'image'
        });
        return true;
      }
      return false;
    }
  },
  async created() {
    this.voteService = this.$httpService(new VotarService(), this);
  }
};
</script>

<style scoped></style>
