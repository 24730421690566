class VotarService {
  constructor() {}
  resources = () => ({
    listaCandidatos: {
      uri: `/v1/votaciones/lista`,
      method: ['get']
    },
    usuario: {
      uri: `/v1/votaciones/usuario`,
      method: ['get']
    },
    votar: {
      uri: `/v1/votaciones/votar`,
      method: ['post']
    },
    votacion: {
      uri: `/v1/votaciones/votacion`,
      method: ['get']
    }
  });
}

export default VotarService;
