<template>
  <div>
    <v-container fluid class="down-top-padding">
      <v-row justify="center">
        <v-col cols="12" sm="4" lg="4" v-for="(candidategrid, index) in candidategrids" :key="index">
          <v-card class="mb-7 ">
            <!--marca-->
            <div v-if="candidategrid.mark" class="cont-img">
              <img src="../../../../assets/images/equis.png" alt="img" class="image-equis" />
            </div>
            <v-card-text class="pa-5 text-center">
              <img :src="candidategrid.img" alt="user" class="rounded-circle" width="120" height="120" />
              <h4 class="title mt-3 mb-0">{{ candidategrid.name }}</h4>
              <small class="subtitle-2 font-weight-regular grey--text text--darken-3">{{ candidategrid.role }}</small>
              <div class="mt-3">
                <v-btn depressed small color="primary" class="mr-2" @click="mark(candidategrid.id)">Marcar</v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="candidates.length > 0" justify="center">
        <v-col cols="12" sm="12" lg="12" class="d-flex justify-center">
          <div class="mt-3">
            <v-btn class="mx-2 my-2" color="gray" @click="clean">Limpiar</v-btn>
            <v-btn class="mx-2 my-2" color="warning" @click="saveBlankVote">Votar en Blanco</v-btn>
            <v-btn class="mx-2 my-2" :disabled="candidateId === null" color="primary" @click="saveVote"
              >Confirmar Votación</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </v-container>
    <!--    <v-card-text> </v-card-text>-->
    <!--    <v-card-actions>-->
    <!--      <v-spacer></v-spacer>-->
    <!--      <v-btn color="error" @click="cancelar()">Cerrar</v-btn>-->
    <!--      <v-btn v-auth-acl="'gh-act-act_reu-insert'" color="primary" submit @click="guardar()">Guardar</v-btn>-->
    <!--    </v-card-actions>-->

    <div v-if="showLoading" class="progress-background">
      <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
    </div>
  </div>
</template>

<script>
import fire from '@/fire';
import Swal from 'sweetalert2';
import VotarService from '@/gmo_votacion/votar/votar/services/VotarService';
// eslint-disable-next-line no-unused-vars
import { decryptData, pathImage } from '@/components4x/utils/utils4x';
import publicIp from 'public-ip';

export default {
  name: 'VotacionFormulario',
  props: {
    iduser: {
      type: String,
      default: ''
    },
    idtrab: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    voteService: null,
    showLoading: false,
    candidateId: null,
    candidateName: null,
    candidates: []
  }),
  computed: {
    candidategrids() {
      return this.candidates;
    }
  },
  methods: {
    async loadCandidates() {
      this.showLoading = true;
      this.candidates = [];
      const res = await this.voteService.get().execResource('listaCandidatos', {
        ideleccion: localStorage.getItem('oko3ldkd543')
      });

      if (res.data.length > 0) {
        res.data.forEach((el) => {
          this.candidates.push({
            id: el.ideleccioncandidato,
            name: el.nombres,
            role: el.descripcion,
            img: pathImage(el.path),
            mark: false
          });
        });
      }

      this.showLoading = false;
    },
    mark(id) {
      this.candidates.map((el) => (el.mark = false));

      for (const candidate of this.candidates) {
        if (candidate.id === id) {
          candidate.mark = true;
          this.candidateId = candidate.id;
          this.candidateName = candidate.name;
          break;
        }
      }
    },
    clean() {
      this.candidates.map((el) => (el.mark = false));
      this.candidateId = null;
      this.candidateName = null;
    },
    async saveBlankVote() {
      if (this.isEmpty()) return;
      this.showLoading = true;
      const { isConfirmed } = await Swal.fire({
        icon: 'question',
        title: '¿Está seguro de votar en blanco?',
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: `Cancelar`,
        confirmButtonText: `Confirmar`,
        reverseButtons: true
      });

      if (!isConfirmed) return (this.showLoading = false);
      const ipv4 = await publicIp.v4();
      //const ipv6 = await publicIp.v6();

      const res = await this.voteService.post().execResource('votar', {
        ideleccion: localStorage.getItem('oko3ldkd543'),
        idelecciondistribucion: localStorage.getItem('jst7uers823'),
        ideleccionusuario: this.iduser,
        ideleccioncandidato: null,
        idtrabajador: this.idtrab,
        ipv4: ipv4,
        ipv6: '',
        descripcion: '',
        blank: 1
      });

      if (res.status) {
        await this.fireUpdateVote(res.code);
        await this.alertDialog('success', res.data);
      } else {
        await this.alertDialog('error', res.data);
      }
      this.showLoading = false;

      this.clean();
      this.emitVotacion();
    },
    async saveVote() {
      if (this.isEmpty()) return;
      this.showLoading = true;
      if (this.candidateId === null) {
        await Swal.fire({
          icon: 'error',
          title: `Por favor, seleccione un candidato antes de confirmar!`,
          confirmButtonText: `OK`
        });
        this.clean();
        this.showLoading = false;
        return;
      }

      const { isConfirmed } = await Swal.fire({
        icon: 'question',
        title: `¿Está seguro de votar por ${this.candidateName}?`,
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: `Cancelar`,
        confirmButtonText: `Confirmar`,
        reverseButtons: true
      });

      if (!isConfirmed) return (this.showLoading = false);

      const ipv4 = await publicIp.v4();
      //const ipv6 = await publicIp.v6();

      const res = await this.voteService.post().execResource('votar', {
        ideleccion: localStorage.getItem('oko3ldkd543'),
        idelecciondistribucion: localStorage.getItem('jst7uers823'),
        ideleccionusuario: this.iduser,
        ideleccioncandidato: this.candidateId,
        idtrabajador: this.idtrab,
        ipv4: ipv4,
        ipv6: '',
        descripcion: '',
        blank: 0
      });

      if (res.status) {
        await this.fireUpdateVote(res.code);
        await this.alertDialog('success', res.data);
      } else {
        await this.alertDialog('error', res.data);
      }
      this.showLoading = false;
      this.clean();
      this.emitVotacion();
    },
    async fireUpdateVote(code) {
      const idel = localStorage.getItem('oko3ldkd543');
      const iddist = localStorage.getItem('jst7uers823');

      if (idel === null || iddist === null || code === null) return;

      const decryptidel = decryptData(idel);
      const decryptiddist = decryptData(iddist);
      const decryptcode = decryptData(code);

      const itemsRef = fire.database().ref('distribucion_candidatos');
      await itemsRef
        .child(decryptidel)
        .child('body')
        .child(decryptiddist)
        .child('candidatos')
        .child(decryptcode)
        .child('count')
        .transaction((count) => count + 1);
    },
    async alertDialog(type, title) {
      return await Swal.fire({
        icon: type,
        title: title,
        confirmButtonText: `OK`
      });
    },
    isEmpty() {
      return this.idtrab === '' || this.iduser === '';
    },
    emitVotacion() {
      this.$emit('emitvotacion');
    }
  },
  async created() {
    this.voteService = this.$httpService(new VotarService(), this);
    await this.loadCandidates();
  }
};
</script>

<style lang="scss">
.marca {
  background-image: url('../../../../assets/images/equis.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: auto;
  margin: auto;
}
.image-equis {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.5;
}
.cont-img {
  width: 100%;
}
</style>
